import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer'
import { Block } from '../../js/block.mjs'

// support for old mobile browsers: adds ~8kb
const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill

const basicObserver = new ResizeObserver((entries, observer) => {
  for (let { target } of entries) {
    let timeout = target.dataset.resizeTimeout
    if (timeout) clearTimeout(Number(timeout))
    timeout = setTimeout(() => {
      target.dispatchEvent(new CustomEvent('doneresizing', { bubbles: true }))
      delete target.dataset.resizeTimeout
      observer.unobserve(target)
    }, 100)
    target.dataset.resizeTimeout = timeout
  }
})

const doneResizing = (element, callback) => {
  basicObserver.observe(element)
  if (callback)
    element.addEventListener('doneresizing', callback, { once: true })
}

const defaults = {
  block: 'team',
  elements: {
    member: 'member',
    preview: 'member-preview',
    headshot: 'headshot',
    img: 'headshot-img',
    initials: 'headshot-fallback',
    info: 'member-info',
    name: 'member-name',
    title: 'member-title',
    button: 'button',
    bioContainer: 'bio',
    bioContent: 'bio-content',
  },
}

// inherits getClassName, getElements, getElement
class Teams extends Block {
  constructor(config = defaults) {
    super(config)
    this.teams = this.getElements().map(e => new Team(e, config))

    let hash = location.hash
    if (hash) {
      location.hash = '' // TODO this isn't sufficient on Firefox
      let open = this.getMember(hash.slice(1))
      if (open) open.deactivate().close()
    }
  }

  getMember(id) {
    for (let team of this.teams) {
      for (let member of team.members) {
        if (member.element.id === id) return member
      }
    }
  }
}

class Team extends Block {
  constructor(e, config = defaults) {
    super(config)
    this.element = e
    this.members = this.getElements('member').map(
      (element, index) => new TeamMember(element, index, config)
    )
  }

  get columns() {
    let columns = getComputedStyle(this.element).getPropertyValue('--columns')
    return Number(columns)
  }

  get rows() {
    return getComputedStyle(this.element)
      .getPropertyValue('grid-template-rows')
      .split(' ')
      .map(row => Number(row.slice(0, -2)))
  }

  set rows(rows) {
    this.element.style.gridTemplateRows = rows
      ? rows.map(r => r.toString() + 'px').join(' ')
      : ''
  }

  setRowHeight(...heights) {
    let currentRows = this.rows
    for (let [row, height] of heights) {
      currentRows[row - 1] = height
    }
    this.rows = currentRows
  }

  getRow(member) {
    return Math.ceil((member.index + 1) / this.columns)
  }

  // TODO add event listener to call openBio on the active bio whenever the screen width changes
  // consider using ResizeObserver https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/ResizeObserver
}

class TeamMember extends Block {
  constructor(e, index, config = defaults) {
    super(config)
    this.element = e
    this.index = index
    this.id = e.id
    this.bio = new TeamBio(this.getElement('bioContainer'), config)
    this.button = this.getElement('button')
    this.headshot = this.getElement('headshot')
    this.grid = e.parentElement

    this.onButtonClick = this._onButtonClick.bind(this)
    this.onMemberActivate = this._onMemberActivate.bind(this)

    this.addEventListeners()
  }

  get columns() {
    let columns = getComputedStyle(this.grid).getPropertyValue('--columns')
    return Number(columns)
  }

  get column() {
    return (this.index % this.columns) + 1
  }

  get row() {
    return Math.ceil((this.index + 1) / this.columns)
  }

  open() {
    this.bio.open()
    return this
  }

  activate() {
    this.headshot.classList.add('team__headshot--active')
    this.isActive = true
    this.element.dispatchEvent(
      new CustomEvent('memberactivate', {
        detail: {
          grid: this.grid,
          index: this.index,
          column: this.column,
          row: this.row,
          bioHeight: this.bio.scrollHeight,
        },
        bubbles: true,
      })
    )
    return this
  }

  close() {
    this.bio.close()
    return this
  }

  deactivate() {
    this.headshot.classList.remove('team__headshot--active')
    this.isActive = false
    return this
  }

  toggle() {
    if (this.isActive) this.deactivate().close()
    else this.activate().open()
    return this
  }

  _onButtonClick(e) {
    e.preventDefault()
    if (this.disabled) return null
    if (this.isActive) {
      this.deactivate().close()
      doneResizing(this.bio.element, () => this.bio.right())
    } else {
      this.activate().open()
    }
  }

  // TODO need to reset row positions when
  // 1) columns change
  // 2) all members in a row have closed
  _onMemberActivate(event) {
    if (event.detail.grid !== this.grid || event.detail.row !== this.row) {
      this.bio.right()
      return this.deactivate().close()
    }
    if (event.detail.index === this.index) return null // TODO don't listen if opening self

    // handle same row activating
    // increase or decrease row height to match new bio
    // once new bio is open & setting row height, close current bio

    if (this.index < event.detail.index) {
      this.bio.left()
    } else {
      this.bio.right()
    }

    // TODO only add this event listener when active
    if (this.isActive) {
      this.deactivate()
      this.bio.height = event.detail.bioHeight
      event.target.addEventListener(
        'doneresizing',
        () => {
          this.close()
        },
        { once: true }
      )
    } else {
      this.disabled = true
      event.target.addEventListener(
        'doneresizing',
        () => {
          this.disabled = false
        },
        { once: true }
      )
    }
  }

  addEventListeners() {
    this.button.addEventListener('click', this.onButtonClick)
    window.addEventListener('memberactivate', this.onMemberActivate)
  }

  removeEventListeners() {
    this.button.removeEventListener('click', this.onButtonClick)
    window.removeEventListener('memberactivate', this.onMemberActivate)
  }
}

class TeamBio extends Block {
  constructor(e, config = defaults) {
    super(config)
    this.element = e
    let elementClass = this.getClassName('bioContainer')
    let contentClass = this.getClassName('bioContent')

    this.content = this.getElement('bioContent')
    this.elementClasses = {
      [elementClass]: this.element,
      [contentClass]: this.content,
    }
    this.modifierClasses = {
      active: '--active',
      left: '--left',
      right: '--right',
    }

    this.close()
  }

  get row() {
    return window.getComputedStyle(this.element).getPropertyValue('--bio-row')
  }

  get scrollHeight() {
    return this.element.scrollHeight
  }

  set height(h) {
    if (this.element.style.height !== this.height + 'px') {
      // make height animateable if it's not already
      this.element.style.height = this.height + 'px'
      this.height = h // set again on next tick
    } else {
      if (typeof h === 'number') h = h + 'px'
      this.element.style.height = h
    }
  }

  get height() {
    return this.element.clientHeight
  }

  set(position) {
    let classes = { ...this.modifierClasses }
    let mod = classes[position]

    for (let eClass in this.elementClasses) {
      let e = this.elementClasses[eClass]
      if (mod) e.classList.add(eClass + mod)
      delete classes[position]
      e.classList.remove(...Object.values(classes).map(c => eClass + c))
    }

    return this
  }

  left() {
    return this.set('left')
  }

  right() {
    return this.set('right')
  }

  open() {
    this.set('active')
    this.height = this.scrollHeight
    this.element.style.overflow = 'visible' // TODO only if another in the row is open
    this.isOpen = true

    // emits doneresizing event
    doneResizing(this.element, () => {
      if (this.isOpen) {
        this.element.style.overflow = 'hidden'
        this.height = 'auto'
      }
    })
    return this
  }

  close() {
    this.element.style.overflow = 'hidden'
    this.height = 0
    this.isOpen = false
    return this
  }

  toggle() {
    if (this.isOpen) this.close()
    else this.open()
    return this
  }
}

export { Teams, Team, TeamMember, TeamBio }
