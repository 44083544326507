const encode = obj => btoa(JSON.stringify(obj))
const decode = str => JSON.parse(atob(str))

const scrollCookie = 'completed_scroll_animations='

const getPathsScrolled = () => {
  let cookieValue = document.cookie
    .split(';')
    .find(cookie => cookie.trim().startsWith(scrollCookie))
    ?.trim()
    ?.slice(scrollCookie.length)
  return cookieValue ? decode(cookieValue) : []
}

const setPathsScrolled = paths => {
  let cookie = scrollCookie + encode(paths)
  document.cookie = `${cookie}; max-age=86400; samesite=strict; path=/`
}

// depends on .animate-on-scroll class in src/_sass/_animations.scss
export default function (scrollable = undefined) {
  const elements = document.querySelectorAll('.animate-on-scroll')
  const pathsScrolled = getPathsScrolled()
  if (
    window.IntersectionObserver &&
    !pathsScrolled.includes(window.location.pathname)
  ) {
    const defaultThreshold = 0.5
    const thresholds = new Set([defaultThreshold])

    for (const element of elements) {
      let customRatio = element.dataset.scrollThreshold
      if (customRatio) thresholds.add(Number(customRatio))

      element.style.setProperty('--scroll-animation-play-state', 'paused')
      element.style.animationPlayState = 'paused'
    }

    const options = {
      root: scrollable,
      rootMargin: '0px 0px 30px 0px',
      threshold: Array.from(thresholds),
    }

    let staggerTimeout,
      staggerAnimations = 0,
      observing = elements.length
    const scrollObserver = new IntersectionObserver((entries, observer) => {
      for (const entry of entries) {
        const customRatio = entry.target.dataset.scrollThreshold
        let isIntersecting =
          entry.isIntersecting &&
          entry.intersectionRatio >= Number(customRatio || defaultThreshold)
        if (!isIntersecting) continue

        entry.target.style.setProperty('--animation-stagger', staggerAnimations)
        entry.target.style.setProperty(
          '--scroll-animation-play-state',
          'running'
        )
        entry.target.style.animationPlayState = 'running'
        observer.unobserve(entry.target)
        observing--

        staggerAnimations++
      }

      clearTimeout(staggerTimeout)
      staggerTimeout = setTimeout(() => {
        staggerAnimations = 0
      }, 10)

      if (observing === 0)
        setPathsScrolled([...pathsScrolled, window.location.pathname])
    }, options)

    elements.forEach(e => scrollObserver.observe(e))
  } else {
    elements.forEach(e => e.classList.remove('animate-on-scroll'))
  }
}
