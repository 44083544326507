import makeHeader from '@dawaltconley/header-basic/browser'
import { Teams } from '../_includes/team/components'
import animateOnScroll from './scroll-animations'

/*
 * Animations
 */

const animate = () =>
  document.documentElement.style.setProperty('--animation-load-delay', '0s')

window.addEventListener('load', () => {
  animate()
})

animateOnScroll()

/*
 * Header
 */

const headerElement = document.getElementById('header')
let header

if (headerElement) header = makeHeader(headerElement)

/*
 * Map
 */

const projectMap = document.querySelector('[data-project-map]')

function highlightProjectStates(map) {
  const highlightStates = JSON.parse(map.getAttribute('data-project-map'))
  const highlightQuery = highlightStates
    .map(s => `.project-map__state.${s.toLowerCase()}`)
    .join(', ')
  const states = map.querySelectorAll(highlightQuery)

  states.forEach((state, i) => {
    let parent = state.parentNode
    parent.removeChild(state)
    state.classList.add('project-map__state--highlighted')
    state.style.setProperty('--highlight-order', i)
    parent.appendChild(state)
  })
}

if (projectMap) {
  highlightProjectStates(projectMap)
}

/*
 * News
 */

const newsPreviews = Array.from(document.querySelectorAll('[data-news]'))

newsPreviews.forEach(preview => {
  const img = preview.querySelector('img')
  if (!img || img.complete) return null
  preview.style.animationPlayState = 'paused'
  preview.style.setProperty('--animation-load-delay', '0s')
  img.addEventListener(
    'load',
    () => {
      preview.style.animationPlayState = 'running'
    },
    {
      passive: true,
      capture: true,
      once: true,
    }
  )
})

/*
 * Team Page
 */

const teams = new Teams()
const team = teams.teams[0]

if (team.members.length === 1) {
  window.addEventListener('load', () => {
    const member = team.members[0]
    member.activate().open()
    member.removeEventListeners()
  })
}

export { teams, header }
