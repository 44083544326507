class Block {
  constructor(block = {}, elements) {
    this._block = typeof block === 'string' ? block : block.block
    this._elements = typeof elements === 'string' ? elements : block.elements
    this._cache = {}
    this.element = document
  }

  getClassName(e) {
    let cls = this._block
    if (e) cls += '__' + this._elements[e]
    return cls
  }

  getElements(name, from = this.element) {
    let cached = this._cache[name]
    if (cached) return cached
    let cls = this.getClassName(name)
    let elements = Array.from(from.getElementsByClassName(cls))
    this._cache[name] = elements
    return elements
  }

  getElement(...args) {
    return this.getElements(...args)[0]
  }
}

export { Block }
